import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1 {...{
      "id": "monetization"
    }}>{`Monetization`}</h1>
    <p>{`Now that users can authenticate, it's time to add a payment button. We'll use Stripe and hook up a `}<inlineCode parentName="p">{`/api`}</inlineCode>{` route to add a role to users.`}</p>
    <p>{`We're loosely following Stripe's instructions: `}<a parentName="p" {...{
        "href": "https://stripe.com/docs/payments/accept-a-payment?integration=elements"
      }}>{`https://stripe.com/docs/payments/accept-a-payment?integration=elements`}</a></p>
    <ol {...{
      "start": 0
    }}>
      <li parentName="ol">{`Install stripe`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`yarn add stripe
`}</code></pre>
    <ol>
      <li parentName="ol">{`Create a server-side prop for payment intent client secret`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const paymentIntent = await stripe.paymentIntents.create({
  amount: 1099,
  currency: "usd",
})

clientSecret: paymentIntent.client_secret
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Install stripe client libs`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`yarn add @stripe/react-stripe-js @stripe/stripe-js
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Render basic Stripe form`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const stripePromise = loadStripe("pk_5WyhDiasKtEhmWiYGeGLvHRqm5Fcn")

const elems = () => (
  <Elements stripe={stripePromise}>
    <CardElement />
  </Elements>
)
`}</code></pre>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Submit payment to Stripe`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`// needs to be in a component inside <Elements> context
const stripe = useStripe()
const elements = useElements()
`}</code></pre>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const result = await stripe.confirmCardPayment("{CLIENT_SECRET}", {
  payment_method: {
    card: elements.getElement(CardElement),
    billing_details: {
      name: "Jenny Rosen",
    },
  },
})

if (result.error) {
  // Show error to your customer (e.g., insufficient funds)
  console.log(result.error.message)
} else {
  // The payment has been processed!
  if (result.paymentIntent.status === "succeeded") {
    // Show a success message to your customer
    // There's a risk of the customer closing the window before callback
    // execution. Set up a webhook or plugin to listen for the
    // payment_intent.succeeded event that handles any business critical
    // post-payment actions.
  }
}
`}</code></pre>
    <h2 {...{
      "id": "5-add-a-webhook-to-authorize-paying-users"
    }}>{`5. Add a webhook to authorize paying users`}</h2>
    <p>{`Webhooks are tiny APIs used for glue-code between services. Ours will receive payment info from Stripe and add a role to Auth0. We'll then be able to check users have that role before giving them access.`}</p>
    <p>{`We're following Stripe Docs: `}<a parentName="p" {...{
        "href": "https://stripe.com/docs/payments/handling-payment-events#create-webhook"
      }}>{`https://stripe.com/docs/payments/handling-payment-events#create-webhook`}</a></p>
    <p>{`All this happens in a `}<inlineCode parentName="p">{`/api`}</inlineCode>{` route that will serve as our webhook.`}</p>
    <ol>
      <li parentName="ol">{`Create an endpoint`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`export default function handler(req, res) {
  if (req.method === "POST") {
    // Process a POST request
  }
}
`}</code></pre>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Hook up local stripe testing`}</li>
    </ol>
    <pre><code parentName="pre" {...{}}>{`stripe listen --forward-to http://localhost:3000/api/stripe-hook
stripe trigger payment_intent.succeeded
`}</code></pre>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Process stripe events`}</li>
    </ol>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`const stripe = Stripe("kvBqgq7OCZs9e730tl9nEbG1I2JDfjWo")
// printed by stripe console, use Dashboard key in production
const endpointSecret = "whsec_uqRFAm4Mjcsj8VZzLsa1k4v8itMtW6HG"
const sig = req.headers["stripe-signature"]

let event
try {
  event = stripe.webhooks.constructEvent(request.body, sig, endpointSecret)
} catch (err) {
  response.status(400).send(\`Webhook Error: \${err.message}\`)
}

console.log(event)

res.json({ received: true })
`}</code></pre>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Add user role on Auth0`}</li>
    </ol>
    <p>{`Modeled after: `}<a parentName="p" {...{
        "href": "https://github.com/Swizec/gumroad-webhook/blob/master/src/gumroadPing.ts"
      }}>{`https://github.com/Swizec/gumroad-webhook/blob/master/src/gumroadPing.ts`}</a></p>
    <pre><code parentName="pre" {...{
        "className": "language-javascript"
      }}>{`import { ManagementClient } from "auth0";

// All secrets should go into ENV vars for production
async function getAuth0Client() {
    const auth0 = new ManagementClient({
        domain: \`\${secrets.domain}.auth0.com\`,
        clientId: secrets.clientId,
        clientSecret: secrets.clientSecret,
        scope: "read:users update:users create:users",
    });

    return auth0;
}

const users = await auth0.getUsersByEmail(purchaseData.email);

await auth0.assignRolestoUser(
    { id: users[0].user_id! },
    {
        roles: [roleId],
    }
);
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      